<template>
  <div>
    <!-- 安检记录 -->
    <div class="content1">
      <div class="all">
        <div class="table" >
          <!-- <el-button  size="small" @click="backtrack">返回</el-button> -->
          <!--数据表格-->
      					<el-table
						ref="tableData"
						:data="tableData"
						style="width: 100%;margin-top: 10px"
						border
						height="calc(100vh - 183px)"
						stripe
						:header-cell-style="{
							color: '#333',
							fontFamily: 'MicrosoftYaHeiUI',
							fontSize: '14px',
							fontWeight: 900,
							textAlign: 'left',
							background: '#f8f8f9',
						}"
					>

						<el-table-column type="index" label="序号" width="70">
						</el-table-column>
						<el-table-column
							prop="dataCode"
							width="170"
							label="工单编号"
						>
							<!-- <template slot-scope="{row}"> -->
							<!-- <el-popover placement="top-start" trigger="hover" :content="row.dataCode">
                  <div  slot="reference" class="twoLines">
                    {{row.dataCode}}
                  </div>
                </el-popover> -->
							<!-- <el-tooltip class="item" effect="dark" :content="row.dataCode" placement="top-start">
                  <div class="twoLines">{{row.dataCode}}</div>
                </el-tooltip>
              </template> -->
						</el-table-column>
						<el-table-column
							prop="taskName"
							label="任务名称"
							width="120"
						>
              <template slot-scope="{row}">
                <el-tooltip class="item" effect="dark" :content="row.taskName" placement="top-start">
                    <div class="twoLines">{{row.taskName}}</div>
                </el-tooltip>
              </template>
						</el-table-column>
						<el-table-column
							prop="residentName"
							label="户主姓名"
							width="120"
							show-overflow-tooltip
						>
						</el-table-column>
						<el-table-column
							prop="mobile"
							label="户主电话"
							width="120"
							show-overflow-tooltip
						>
						</el-table-column>
						<el-table-column
							prop="userTypeName"
							label="用户分类"
							width="120"
						>
						</el-table-column>
						<el-table-column
							prop="addrName"
							label="片区"
							width="120"
						>
							<template slot-scope="{ row }">
								<!-- <el-popover placement="top-start" trigger="hover" :content="row.addrName">
                  <div  slot="reference" class="twoLines">
                    {{row.addrName}}
                  </div>
                </el-popover> -->
								<el-tooltip
									class="item"
									effect="dark"
									:content="
										row.addrName + '->' + row.addrsName
									"
									placement="top-start"
								>
									<div class="twoLines">
										{{ row.addrName }}->{{ row.addrsName }}
									</div>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column
							prop="address"
							label="地址"
							width="120"
						>
							<template slot-scope="{ row }">
								<!-- <el-popover placement="top-start" trigger="hover" :content="row.address">
                  <div  slot="reference" class="twoLines">
                    {{row.address}}
                  </div>
                </el-popover> -->
								<el-tooltip
									class="item"
									effect="dark"
									:content="row.address"
									placement="top-start"
								>
									<div class="twoLines">
										{{ row.address }}
									</div>
								</el-tooltip>
							</template>
						</el-table-column>

						<el-table-column
							prop="deptName"
							label="负责部门"
							show-overflow-tooltip
							width="100"
						>
						</el-table-column>
						<el-table-column
							prop="safetyResults"
							label="安检结果"
							width="80"
						>
							<template slot-scope="{ row }">
								<p>
									{{
										getDictLabel(
											checkerList,
											row.safetyResults,
											"id",
											"label"
										)
									}}
								</p>
							</template>
						</el-table-column>
						<el-table-column
							prop="inspectorName"
							label="安检员"
							width="120"
						>
							<template slot-scope="{ row }">
								<div class="twoLines">
									<p>
										{{ row.inspectorName }}
										<br />
										<span v-if="row.inspectorMobile"
											>({{ row.inspectorMobile }})</span
										>
									</p>
								</div>
								<!-- </el-tooltip> -->
							</template>
						</el-table-column>
						<el-table-column
							prop="checkTime"
							label="检查时间"
							width="160"
						></el-table-column>
						<el-table-column
							prop="inspectionTime"
							label="检查时长"
							width="120"
							show-overflow-tooltip
						>
						</el-table-column>
						<el-table-column
							prop="reviewResults"
							label="复查结果"
							width="120"
							show-overflow-tooltip
						>
							<template slot-scope="{ row }">
								<p>
									{{
										getDictLabel(
											reviewResultsData,
											row.reviewResults,
											"id",
											"label"
										)
									}}
								</p>
							</template>
						</el-table-column>
						<el-table-column
							prop="reviewerName"
							label="复查人员"
							width="120"
							show-overflow-tooltip
						>
							<template slot-scope="{ row }">
								<div class="twoLines">
									<p>
										{{ row.reviewerName }}
										<br />
										<span v-if="row.reviewerMobile"
											>({{ row.reviewerMobile }})</span
										>
									</p>
								</div>
								<!-- </el-tooltip> -->
							</template>
						</el-table-column>
						<el-table-column
							prop="reviewCheckTime"
							label="复查时间"
							width="160"
						></el-table-column>
						<el-table-column
							prop="reviewInspectionTime"
							label="复查时长"
							width="120"
							show-overflow-tooltip
						>
						</el-table-column>
						<el-table-column
							prop="auditStatus"
							label="审核状态"
							width="120"
							show-overflow-tooltip
						>
							<template slot-scope="{ row }">
								<p>
									{{
										getDictLabel(
											auditStatusList,
											row.auditStatus,
											"id",
											"label"
										)
									}}
								</p>
							</template>
						</el-table-column>
						<el-table-column
							prop="explainState"
							label="说明"
							width="120"
						>
              <template slot-scope="{row}">
                <el-tooltip class="item" effect="dark" :content="row.explainState" placement="top-start">
                  <div class="twoLines">{{row.explainState}}</div>
                </el-tooltip>
              </template>
						</el-table-column>
						<el-table-column
							prop="auditBy"
							label="审核人"
							width="120"
							show-overflow-tooltip
						>
						</el-table-column>

						<el-table-column
							width="120"
							fixed="right"
							prop="problem"
							label="问题登记"
							show-overflow-tooltip
						>
							<template slot-scope="{ row }">
								<p>
										<span v-if="row.safetyResults==2|| row.safetyResults==3"></span>
										<span v-else>
											{{
												getDictLabel(
													problemLists,
													row.problem,
													"id",
													"label"
												)
											}}
										</span>
								</p>
								
							</template>
						</el-table-column>
						<el-table-column
							fixed="right"
							prop="statusFlag"
							label="工单状态"
							width="80"
						>
							<template slot-scope="{ row }">
								<el-tag
									:type="
										row.statusFlag == '1'
											? 'success'
											: 'danger'
									"
									>{{
										getDictLabel(
											statusList,
											row.statusFlag,
											"id",
											"label"
										)
									}}</el-tag
								>
							</template>
						</el-table-column>

						<el-table-column
							fixed="right"
							prop="operation"
							label="操作"
							align="left"
							width="180"
						>
							<template slot-scope="{ row }">
								<el-button
                  v-if="$anthButtons.getButtonAuth('jmck')"
									type="primary"
									size="small"
									@click="addItem(3, row)"
									>查看</el-button
								>
								<el-button
                    v-if="$anthButtons.getButtonAuth('jmzd')"
									type="primary"
									size="small"
									@click="getTop(row)"
									>{{
										row.isTop == 1 ? "取消置顶" : "置顶"
									}}</el-button
								>
							</template>
						</el-table-column>
					</el-table>
          <el-pagination
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              :page-sizes="[10, 30, 50, 100]"
              :page-size="searchBox.size"
              :current-page="searchBox.current"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import  {securityCheckRecordPageList} from '../../../RequestPort/peopleUser/people'
	import {
		getResidentWorkData,
		addrDropDown1,
		getAddrDropDown,
		dataBatchAudit,
		getDataTop,
		inspectorDropDown1,
	} from "../../../RequestPort/workorder/maintenRecord";
	import { getDictLabel } from "../../../utils/common"

	export default {
  name: "lockout",
  data(){
    return{
      getDictLabel,
      // 查询条件
      searchBox:{
        current:1,
        size: 100
      },
      total:null,
      // 列表数据
      tableData: [],
      // 安检结果
				checkerList: [
					{ label: "正常安检", id: 1 },
					{ label: "到访不遇", id: 2 },
					{ label: "拒绝安检", id: 3 }
				],
				auditStatusList: [
					{ label: "未审核", id: 1 },
					{ label: "已审核", id: 2 },
					{ label: "驳回", id: 3 },
				],
				reviewResultsData: [
					{ label: "正常复查", id: 1 },
					{ label: "到访不遇", id: 2 },
				],
				problemList: [
					{ label: "有问题未处理", id: "1" },
					{ label: "问题已处理", id: "2" },
					{ label: "无问题", id: "3" },
				],
				problemLists: [
					{ label: "无问题", id: "1" },
					{ label: "有问题未处理", id: "2" },
					{ label: "问题已处理", id: "3" },
				],
				// 工单状态
				statusList: [
					{ label: "已完成", id: 1 },
					{ label: "未完成", id: 2 },
					{ label: "待复查", id: 3 },
				],
				// 采集模板下拉
				taskTplIdData: [],
				// 关键字条件
				searchBar: [
					{ label: "用户名称", id: 1, name: "residentName" },
					{ label: "联系人", id: 2, name: "contactName" },
					{ label: "电话", id: 3, name: "mobile" },
					{ label: "地址", id: 4, name: "address" },
					{ label: "工单编号", id: 5, name: "dataCode" },
				],
				// 时间
				times: [],
				// 导出弹窗
				editConductVisible: false,
				loading: true,
    }
  },
  mounted() {
    this.loadList(this.searchBox)
  },
  methods:{
    	/**
			 * 查看页面
			 */
			addItem(index, row) {
				this.$router.push({
					path: "/home/workorder/civilDetails",
					query: {
						dataId: row.dataId,
						type: row.finishType == 3 ? 1 : 0,
						taskId: row.taskId,
						residentId: row.residentId,
						safetyResults: row.safetyResults,
						auditStatus: row.auditStatus,
					},
				});
			},
    /**
     * 返回
     */
    getTop(row) {
				getDataTop({ dataId: row.dataId }).then((res) => {
					this.$message.success(res.data);
					this.loadList();
				});
		},
    backtrack(){
      this.$router.back(-1)
    },
    examine(row){
      console.log(row)
      this.$router.push({
        path: "/home/workorder/civilDetails",
        query: {dataId:row.dataId,type:row.finishType == 3 ? 1 : 0,taskId:row.taskId,residentId:row.residentId,statusFlag:row.statusFlag,buttonShow:1},
      });
    },
    /**
     * 更换条数
     */
    handleSizeChange(val){
      this.searchBox.current = 1
      this.searchBox.size = val
      this.loadList(this.searchBox)
    },
    /**
     * 更换页码
     */
    handleCurrentChange(val){
      this.searchBox.current = val
      this.loadList()
    },
    /**
     * 获取列表
     */
    loadList(obj){
      if(obj){
        this.searchBox.current = 1
      }
      this.searchBox.userId = this.$route.query.id
      securityCheckRecordPageList(this.searchBox).then(res=>{
        console.log(this.tableData)
					this.tableData = res.data.records;
					this.total = res.data.total;
      })
    },
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-upload--picture-card{width:100px;height:100px;line-height:100px}
::v-deep .el-upload-list--picture-card .el-upload-list__item{width:100px;height:100px;line-height:100px}
.detil-code{
  padding-left: 10px;
  font-size: 16px;
  height: 26px;
  line-height: 16px;
  color: #000000;
  position: relative;
}
.detil-code:before{
  content: '';
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007AFF;
  top: 0;
  left: 0;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
.SearchBtn{
  margin-left: 10px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
}
.el-radio{
  margin-right:0;
}
.el-form-item{
  margin-bottom:0;
}
</style>